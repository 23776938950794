@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;1,300;1,700&display=swap");

// background-color: rgb(46, 46, 46);
// background-color: #386fa4;
// background-color: #058e3f;

$lg: 1200px;
$md: 992px;
$sm: 768px;
$xs: 576px;

body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  background-color: #fdfffcf3;
}

.examenid {
  display: none;
}

.imgmax {
  width: 100%;
}

.call-to-action {
  background-color: $prime;
  padding: 8px;
  font-weight: bold;
  border-top: 2px solid black;
  a {
    color: black;
  }
  &-action {
    background-color: rgb(36, 141, 36);
    text-align: center;
    a{
      color: white;
      margin: auto;

    }
  }
}

.paymentstatus {
  &__Betaald {
    background-color: green;
    color: white;
    border-radius: 3px;
    padding: 3px 6px;
    font-weight: bold;
  }
  &__Verlopen,
  &__Geannuleerd {
    background-color: red;
    color: white;
    border-radius: 3px;
    padding: 3px 6px;
    font-weight: bold;
  }
  &__Open {
    background-color: orange;
    color: white;
    border-radius: 3px;
    padding: 3px 6px;
    font-weight: bold;
  }
  &__Herinnering {
    background-color: rgb(255, 230, 0);
    color: white;
    border-radius: 3px;
    padding: 3px 6px;
    font-weight: bold;
  }
  &__Nieuw {
    background-color: rgb(0, 140, 255);
    color: white;
    border-radius: 3px;
    padding: 3px 6px;
    font-weight: bold;
  }
}

.block {
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
  &-blue {
    background-color: #4ac5ffad;
  }
  &-orange {
    background-color: rgba(255, 162, 63, 0.69);
  }
  &-green {
    background-color: rgba(83, 236, 83, 0.52);
  }
  &-prime {
    background-color: $prime;
  }
}

.course {
  &__show {
    position: relative;
    padding: 0;
    margin-bottom: 3rem;
    &-header {
      background-color: $prime;
      clip-path: polygon(0 0, 100% 0, 100% 40%, 0% 100%);
      padding: 1rem 1rem 3rem 1rem;
      position: relative;
      min-height: 200px;
      &-price {
        background-color: white;
        border-radius: 2px;
        color: black;
        padding: 10px;
        font-weight: 400;
        margin: 20px 0 0 20px;
        display: inline-block;
      }
    }
  }
  &__cta {
    // background-color: rgb(13, 160, 0);
    border: 1px solid rgb(151, 151, 151);
    margin: 20px 0;
    padding: 15px;
    h2 {
      text-align: center;
      margin-bottom: 15px;
    }
    &-button {
      color: white;
      background-color: rgb(255, 136, 0);
      padding: 8px;
      font-weight: bold;
      border-radius: 3px;
      &:hover {
        color: white;
        text-decoration: underline;
      }
    }
  }
}

.right-side {
  margin-top: -110px;
  position: absolute;
  right: 10px;
  z-index: 10;
  .button {
    padding: 10px;
    border-radius: 2.5px;
    border: white 0.5px solid;
    font-weight: 400;

    background-color: #008008;
    color: white;
    margin: 15px 0;
  }
  .country-flag {
    margin: 15px 0;
    width: 100%;
  }
}

.category {
  background-position: center;
  background-size: cover;
  margin-bottom: 2rem;
  &__content {
    margin: 3rem 3rem;
    background-color: rgba(255, 255, 255, 0.7);
    p,
    h1 {
      padding: 2rem;
    }
    p {
      font-weight: bold;
    }
  }
}

.header {
  height: 200px;
  margin-bottom: 20px;
  color: white;
  text-shadow: 0 0 2px #000;
  padding: 30px;
  font-weight: bold;
  p {
    font-size: 19px;
  }
  a {
    color: white;
    text-decoration: underline;
  }
  h1 {
  }
}

.navbar {
  margin-bottom: 5px;
  &-brand {
    color: black;
    font-size: 30px;
    font-weight: bold;
  }
  &-nav {
    .nav-item {
      background-color: $prime;
      margin: 4px 0;
      .nav-link {
        color: #3e3e3e;
        text-transform: uppercase;
        font-weight: bold;
        margin-left: 8px;
      }
    }

    @media only screen and (min-width: 600px) {
      .nav-item {
        background-color: $prime;
        transform: skew(-11deg, 0deg) translate(7px, 0);
        margin: 0 5px;
        .nav-link {
          color: #3e3e3e;
          text-transform: uppercase;
          font-weight: bold;
          margin: auto;
        }
      }
    }
  }
  &-toggler {
    background-color: $prime;
    &-icon {
      width: auto;
      padding: 0.3rem;
    }
  }
}

.application_form {
  background-color: rgba(128, 128, 128, 0.178);
  padding: 10px;
}

.courses {
  padding-left: 0;
  .course {
    margin: 15px 0;
    list-style: none;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: whitesmoke;
  }
}

.events {
  padding-left: 0;
  .event {
    margin-bottom: 30px;
    list-style: none;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    &:nth-child(n + 11) {
      display: none;
    }
  }
}

.applicant {
  background-color: whitesmoke;
}

.cart {
  background-color: rgb(226, 226, 226);
  p {
    margin: 0px;
  }
  .cursist {
    background-color: rgba(211, 209, 209, 0.651);
    margin: 3px 0;
  }
}

.event_summary {
  // background-color: rgba(82, 184, 232, 0.2);
  border-radius: 4px;
  padding: 5px;
  border: 1px solid rgba(39, 149, 216, 0.84);
  background-color: rgba(39, 149, 216, 0.1);
  padding: 5px;
  .event_summary_item {
    margin: 8px 0;
    p {
      margin: 5px 0;
      position: relative;
      .icon-bg {
        background-color: #75bce5;
        border-radius: 25%;
        width: 35px;
        display: inline-block;
        height: 35px;
        text-align: center;
        vertical-align: middle;
        line-height: 35px;
        i.fa {
          color: white;
        }
      }
    }
  }
}

.tiles {
  .tile {
    border: 2px solid rgb(219, 219, 219);
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    &__title {
      font-size: 1.5rem;
    }
    &__image {
      max-height: 80px;
    }
  }
}

.question-bar {
  background-color: $prime;
  position: relative;
  padding-top: 15px;
  &__advisor {
    height: 300px;
    img {
      height: 100%;
    }
  }
  &__text {
    margin-top: 50px;
    &-title {
      font-size: 1.6rem;
      font-weight: bold;
    }
    &-text {
      font-size: 1.6rem;
    }
  }
  &__buttons {
    margin-top: 50px;
    a {
      margin-bottom: 20px;
    }
  }
}

.top-header {
  background-color: #3e3e3e;
  color: white;
  // padding: 5px 0;
  a {
    color: white;
    &:hover {
      color: white;
    }
  }

  .fas {
    color: rgba(255, 196, 0, 0.781);
  }
}

#locationmap {
  height: 200px;
  width: 100%;
}

.usp_items {
  // background-color: rgba(83, 236, 83, 0.52);
  border-radius: 0 0 4px 4px;
  padding: 5px;
  border: 1px solid slategray;
  margin-top: 15px;
  ul {
    list-style: none;
    li {
      .icon {
        background-color: rgba(83, 236, 83, 0.52);
        display: inline-block;
        border-radius: 20px;
        margin: 5px 5px;
        img {
          height: 1.5rem;
          margin: 5px;
        }
      }
    }
  }
}

.helpdesk-icons {
  text-align: center;
  div {
    padding: 5px 10px;
  }
  svg {
    height: 60px;
    display: inline-block;
    width: 100%;
  }
}

.company {
  margin: 10px 0;
}

.student {
  margin: 10px 0;
}

.summary {
  // background-color: rgba(255, 251, 31, 0.514);
  background-color: $prime;
  border-radius: 4px;
  padding: 8px;
  p {
    margin: 3px 0;
  }
  li {
    list-style: none;
    margin: 5px 0;
  }
}

footer {
  background-color: rgba(0, 0, 0, 0.815);
  color: white;
  .container-fluid,
  .container,
  &.container {
    a {
      color: white;
      font-size: 14px;
    }
    ul {
      padding-left: 0;
      li {
        list-style: none;
      }
    }
  }
  .payment-methods {
    img {
      width: auto;
    }
  }
  .copyright {
    font-size: 12px;
    text-align: center;
  }
  .contact {
    p {
      line-height: 8px;
    }
  }

  .social_icons {
    .social_icon {
      margin: 8px;
      svg {
        width: 35px;
        color: rgb(236, 236, 236);
      }
    }
  }
}

.helpdesk_banner {
  border: 1px solid rgb(236, 236, 236);
  padding: 10px;
  margin-bottom: 20px;
  h3,
  p {
    text-align: center;
  }
}

.course_home {
  border: 1px solid #3e3e3e88;
  position: relative;
  margin: 5px;
  padding: 5px;
  height: auto;
  text-align: center;
  border-radius: 3px;
  background-size: cover;
  background-position: center;
  background-color: #80808014;
  a {
    margin-top: 8px;
  }
  &__title {
    font-weight: bold;

    background-color: white;
    border-radius: 3px;
  }
  &__cta {
    position: absolute;
    bottom: 10px;
    left: 20px;
    right: 20px;
    text-align: center;
  }
}

.company_student {
  border: 1px solid rgb(43, 43, 43);
  padding: 5px;
  background-color: rgba(230, 230, 230, 0.19);
}

.event_student {
  background-color: white;
  margin-top: 5px;
  padding: 3px;
  border: 1px rgb(156, 156, 156) solid;
}

.standard_list_view {
  border: 1px solid rgb(43, 43, 43);
  padding: 5px;
  background-color: rgba(230, 230, 230, 0.19);
  margin: 10px 0;
}

.admin_index_nav {
  background-color: rgba(0, 128, 0, 0.753);
  margin: 20px 10px;
  text-align: center;
  padding: 20px;
  border-radius: 4px;
  svg {
    width: 100px;
    color: white;
    text-align: center;
  }
  p {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 23px;
  }
  &:hover {
    text-decoration: none;
  }
}

.paymentbox {
  background-color: rgba(0, 128, 0, 0.671);
  border: 1px solid green;
  border-radius: 3px;
  padding: 20px;
  text-align: center;
  &--text {
    color: white;
    font-weight: bold;
  }
}

.badge-success,
.badge-danger {
  font-size: 16px;
}

.location {
  &__department {
    &__item {
      background-color: $prime;
      display: inline-block;
      padding: 10px;
      line-height: 10px;
      margin: 5px;
    }
  }
}

form {
  margin: 20px 0;
}

.submission-success {
  padding: 15px;
  background-color: #06af47;
  border-radius: 3px;
  color: white;
  font-weight: bold;
}

.loading-order {
  // background-color: rgba(255, 255, 255, 0.479);
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
}

// SLICK SLIDER

.slider {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease;
  position: relative;
  .info {
    color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    text-align: center;
    z-index: 10;
    padding: 25px;
    h1 {
      text-shadow: rgb(90, 90, 90) 0px 0px 6px;
      font-size: 2rem;
    }
  }
}

.slider.slick-initialized {
  visibility: visible;
  opacity: 1;
}

.slick-slider {
  width: 100%;
}

.slick-track {
  .slick-slide {
    height: 400px;
    @media (max-width: $sm) {
      height: 200px;
    }
    img {
      aspect-ratio: attr(width) / attr(height);
    }
  }
}

.dashboard {
  &_block {
    background-color: lightblue;
    padding: 10px;
    border-radius: 3px;
  }
}

.helpdesk {
  ul {
    list-style: none;
    padding: 0;
    li {
    }
  }
}

.contact-methods {
  margin: 20px 0;
  .method {
    background-color: orange;
    text-align: center;
    font-weight: bold;
    margin: 0 15px;
    padding: 15px;
  }
}

.exam {
  margin: 25px;
}

.authentication {
  &__logo {
    // margin: 20px 0;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__form {
    background-color: rgba(179, 222, 235, 0.37);
    border: 2px solid rgba(98, 169, 190, 0.5);
    border-radius: 3px;
    padding: 10px;
  }
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.addition-box {
  padding: 5px;
  margin: 0 5px;
  &:hover {
    background-color: rgba(255, 166, 0, 0.562);
    border-radius: 2px;
  }
  input[type="checkbox"]:checked {
    background-color: rgba(255, 166, 0, 0.562);
  }
}

.review {
  border: 0.5px solid rgb(155, 155, 155);
  padding: 8px;
  border-radius: 3px;
  margin: 0.5rem 0;
  background-color: rgb(238, 238, 238);
  box-shadow: 0 0 3px #ccc;
}

.fc {
  direction: ltr;
  text-align: left;
}
.fc table {
  border-collapse: collapse;
  border-spacing: 0;
}
.fc .btn {
  line-height: 1.2em;
}

html .fc {
  font-size: 1em;
}

.fc table {
  font-size: 1em;
}
.fc td,
.fc th {
  padding: 1px;
  vertical-align: top;
}

.fc-header td {
  white-space: nowrap;
}

.fc-header-left {
  width: 25%;
  text-align: left;
}

.fc-header-center {
  text-align: center;
}

.fc-header-right {
  width: 25%;
  text-align: right;
}

.fc-header-title {
  display: inline-block;
  vertical-align: top;
}
.fc-header-title h2 {
  margin-top: 0;
  white-space: nowrap;
}

.fc .fc-header-space {
  padding-left: 10px;
}

.fc-header .fc-button {
  margin-bottom: 1em;
  vertical-align: top;
  margin-right: -1px;
}
.fc-header .fc-corner-right {
  margin-right: 1px;
}
.fc-header .ui-corner-right {
  margin-right: 0;
}
.fc-header .fc-state-hover,
.fc-header .ui-state-hover {
  z-index: 2;
}
.fc-header .fc-state-down {
  z-index: 3;
}
.fc-header .fc-state-active,
.fc-header .ui-state-active,
.fc-header .ui-state-down {
  z-index: 4;
  background-color: #e6e6e6;
  background-color: #d9d9d9;
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
    0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
    0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.fc-content {
  clear: both;
}

.fc-view {
  width: 100%;
  overflow: hidden;
}

.fc .ui-widget-header {
  border-color: #dddddd;
  padding: 4px 0;
}

thead th.fc-first {
  border-top-left-radius: 5px;
  -moz-border-top-left-radius: 5px;
  -webkit-border-top-left-radius: 5px;
}

thead th.fc-last {
  border-top-right-radius: 5px;
  -moz-border-top-right-radius: 5px;
  -webkit-border-top-right-radius: 5px;
}

.fc-state-highlight {
  background: #ffffcc;
}

.ui-state-highlight.fc-today {
  background: #f4f4f4;
  margin: 2px !important;
  border: 0;
  border-left: 1px solid #dddddd;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

.fc-cell-overlay {
  background: #99ccff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}

.fc-button {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.fc-state-default {
  border-style: solid;
  border-width: 1px 0;
}

.fc-button-inner {
  position: relative;
  float: left;
  overflow: hidden;
}

.fc-state-default .fc-button-inner {
  border-style: solid;
  border-width: 0 1px;
}

.fc-button-content {
  position: relative;
  float: left;
  height: 1.9em;
  line-height: 1.9em;
  padding: 0 0.6em;
  white-space: nowrap;
}
.fc-button-content .fc-icon-wrap {
  position: relative;
  float: left;
  top: 50%;
}
.fc-button-content .ui-icon {
  position: relative;
  float: left;
  margin-top: -50%;
  *margin-top: 0;
  *top: -50%;
}

.fc-state-default {
  border-style: solid;
  border-color: #cccccc #bbbbbb #aaaaaa;
  background: #f3f3f3;
  color: black;
}
.fc-state-default .fc-button-effect {
  position: absolute;
  top: 50%;
  left: 0;
}
.fc-state-default .fc-button-effect span {
  position: absolute;
  top: -100px;
  left: 0;
  width: 500px;
  height: 100px;
  border-width: 100px 0 0 1px;
  border-style: solid;
  border-color: white;
  background: #444444;
  opacity: 0.09;
  filter: alpha(opacity=9);
}
.fc-state-default .fc-button-inner {
  border-style: solid;
  border-color: #cccccc #bbbbbb #aaaaaa;
  background: #f3f3f3;
  color: black;
}

.fc-state-hover {
  border-color: #999999;
}
.fc-state-hover .fc-button-inner {
  border-color: #999999;
}

.fc-state-down {
  border-color: #555555;
  background: #777777;
}
.fc-state-down .fc-button-inner {
  border-color: #555555;
  background: #777777;
}

.fc-state-active {
  border-color: #555555;
  background: #777777;
  color: white;
}
.fc-state-active .fc-button-inner {
  border-color: #555555;
  background: #777777;
  color: white;
}

.fc-state-disabled {
  color: #999999;
  border-color: #dddddd;
  cursor: default;
}
.fc-state-disabled .fc-button-inner {
  color: #999999;
  border-color: #dddddd;
}
.fc-state-disabled .fc-button-effect {
  display: none;
}

.fc-event {
  border-style: solid;
  border-width: 0;
  font-size: 0.85em;
  cursor: default;
}

a.fc-event,
.fc-event-draggable {
  cursor: pointer;
}

a.fc-event {
  text-decoration: none;
}

.fc-rtl .fc-event {
  text-align: right;
}

.fc-event-skin {
  border: 0;
  background-color: #3366cc;
  color: white;
}

.fc-event-inner {
  position: relative;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 0;
  overflow: hidden;
}

.fc-event-time,
.fc-event-title {
  padding: 0 1px;
}

.fc .ui-resizable-handle {
  display: block;
  position: absolute;
  z-index: 99999;
  overflow: hidden;
}

.fc-event-hori {
  border-width: 1px 0;
  margin-bottom: 1px;
}
.fc-event-hori .ui-resizable-e {
  top: 0 !important;
}
.fc-event-hori .ui-resizable-w {
  top: 0 !important;
  left: -3px !important;
  width: 7px !important;
  height: 100% !important;
  cursor: w-resize;
}
.fc-event-hori .ui-resizable-handle {
  _padding-bottom: 14px;
}

table.fc-border-separate {
  border-collapse: separate;
}

.fc-border-separate th,
.fc-border-separate td {
  border-width: 1px 0 0 1px;
}
.fc-border-separate th.fc-last,
.fc-border-separate td.fc-last {
  border-right-width: 1px;
}
.fc-border-separate tr.fc-last th,
.fc-border-separate tr.fc-last td {
  border-bottom-width: 1px;
}
.fc-border-separate tbody tr.fc-first td,
.fc-border-separate tbody tr.fc-first th {
  border-top-width: 0;
}

.fc-grid th {
  text-align: center;
}
.fc-grid .fc-day-number {
  float: right;
  padding: 0 2px;
}
.fc-grid .fc-other-month .fc-day-number {
  opacity: 0.3;
  filter: alpha(opacity=30);
}

.fc-rtl .fc-grid .fc-day-number {
  float: left;
}
.fc-rtl .fc-grid .fc-event-time {
  float: right;
}

.fc-agenda table {
  border-collapse: separate;
}

.fc-agenda-days th {
  text-align: center;
}

.fc-agenda .fc-agenda-axis {
  width: 50px;
  padding: 0 4px;
  vertical-align: middle;
  text-align: right;
  white-space: nowrap;
  font-weight: normal;
}
.fc-agenda .fc-day-content {
  padding: 2px 2px 1px;
}

.fc-agenda-days .fc-agenda-axis {
  border-right-width: 1px;
}
.fc-agenda-days .fc-col0 {
  border-left-width: 0;
}

.fc-agenda-allday th {
  border-width: 0 1px;
}
.fc-agenda-allday .fc-day-content {
  min-height: 34px;
}

.fc-agenda-divider-inner {
  height: 2px;
  overflow: hidden;
}

.fc-widget-header .fc-agenda-divider-inner {
  background: #eeeeee;
}

.fc-agenda-slots th {
  border-width: 1px 1px 0;
}
.fc-agenda-slots td {
  border-width: 1px 0 0;
  background: none;
}
.fc-agenda-slots td div {
  height: 20px;
}
.fc-agenda-slots tr.fc-slot0 th,
.fc-agenda-slots tr.fc-slot0 td {
  border-top-width: 0;
}
.fc-agenda-slots tr.fc-minor th,
.fc-agenda-slots tr.fc-minor td {
  border-top-style: dotted;
}
.fc-agenda-slots tr.fc-minor th.ui-widget-header {
  *border-top-style: solid;
}

.fc-event-vert {
  border-width: 0 1px;
}
.fc-event-vert .fc-event-head,
.fc-event-vert .fc-event-content {
  position: relative;
  z-index: 2;
  width: 100%;
  overflow: hidden;
}
.fc-event-vert .fc-event-time {
  white-space: nowrap;
  font-size: 10px;
}

.fc .ui-draggable-dragging .fc-event-bg,
.fc-select-helper .fc-event-bg {
  display: none;
}

.fc-event-vert .ui-resizable-s {
  bottom: 0 !important;
}
