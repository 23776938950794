$prime: #ff8800;

@import "style.scss";

.ul-usp{
    padding: 0;
    &__item{
        list-style: none;
        display: inline-flex;
        position: relative;
        display: table;
        margin: 8px 0px;
        svg.circle-icon {
            background: black;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            text-align: center;
            line-height: 100px;
            vertical-align: middle;
            padding: 20px;
            margin-right: 0.5rem;
            color: white;
            display: inline-block;
        }
        p{
            display: table-cell;
            vertical-align: middle;
        }
    }
}



.top-nav{
    margin-top: -3px;
    ul{
        li{
            display: inline-block;
            margin: 0 5px;
            a{
                font-size: 15px;
                border-bottom: 2px solid black;
                color: black;
            }
        }
    }
}


.navbar{
    margin-bottom: 5px;
}

.about{
    background-color: #fdd416;
    &__content{
        padding: 10px;
    }
    &__photo{
        background-image: url("../../public/media/image/about_photo.jpg");
        background-size: cover;
        padding: 0;
        &::before{
            position: absolute;
            z-index: 2;
            content: '';
            display: block;
            height: 100%;
            width: 8.88889em;
            background-image: linear-gradient(to right, #fdd416, rgba(253, 212, 22, 0));
        }
    }
}


footer{
    &.container{
        background-color: rgba(0, 0, 0, 0.815);
        background-color: #3e3e3e;
        color: white;
        a{
            color: white;
        }
        ul{
            padding-left: 0;
            li{
                list-style: none;
            }
        }
    }
    .copyright{
        font-size: 12px;
        text-align: center;
    }
    .contact{
        p{
            line-height: 8px;
        }
    }

    .social_icons{
        .social_icon{
            margin: 8px;
            svg{
                width: 35px;
                color: rgb(236, 236, 236);
            }
        }
    }
}

.course_home{
    border: 1px solid #3e3e3e88;
    position: relative;
    margin: 5px;
    padding: 5px;
    height: 250px;
    text-align: center;
    border-radius: 3px;
    a{
        margin-top: 8px;
    }
    &__title{
        font-weight: bold;
    }
    &__cta{
        position: absolute;
        bottom: 10px;
        left: 20px;
        right: 20px;
        text-align: center;
    }
}
